.container.contact__container {
    width: 58%;
    display: grid;
    gap: 8.5rem;
    grid-template-columns: 30% 58%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border: 1px solid var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    font-size: 9px;
    display: inline-block;
    margin-top: 0.7rem;
}

/*========== form ============*/
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    background: transparent;
    border-radius: 0.5rem;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

/* ============ media query (medium devices) =========== */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template__columns: 1fr;
        gap: 2rem;

    }

}

/* ============ media query (small devices) =========== */

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }

    .contact__option {
        padding: 0.2rem;
    }
    .contact__option h4 {
        word-wrap: break-word; /* Ensures long email addresses break and wrap */
        word-break: break-word; 
        font-size: 12px !important;
    }
    .contact__option h5 {
        word-wrap: break-word; /* Ensures long email addresses break and wrap */
        word-break: break-word; 
        font-size: 11px;
    }
}