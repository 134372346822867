/* about.css */

#about {
    padding: -40px 0;
}

.about__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    /* place-items: center; */

}

.about__me-img {
    border-radius: 2rem ;
    overflow: hidden !important;
    transform: rotate(10deg);
    transition: var(--transition); 

}

.about__me-img:hover {

    transform: rotate(0deg);
}

.about__content {
    flex: 2;
    padding: 0 2rem;
}

.about__tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

}

.about__tabs button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: none;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    font-size: 1rem;
    background-color: #4db5ff;
    width: 160px;
    margin: 10px;
}

.about__tabs button:hover {
    background-color: #fff;
}

.about__tabs button.active {
    background-color: #2C2C6C; /* Active tab color */
    color: white; /* Active tab text color */
    border-radius: 5px;
}

.about__tab-content {
    padding: 10px;

    border-radius: 5px;
    /* background-color: #f9f9f9; */
}

.about__cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.about__card {
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex: 1;
    margin: 0 1rem;
}

.about-icon {
    font-size: 2rem;
    color: #007bff; /* Icon color */
}

.about__card h5 {
    margin: 1rem 0 0.5rem;
}

.about__card small {
    color: #666;
}

.experience__cont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    

}
.experience__cont>div {
    padding: 20px !important;
}

.experience__item {
    flex: 1 1 calc(48% - 20px); /* Adjusts to 3 columns with some spacing */
    margin: 10px; /* Space between items */
    padding: 20px;
    background-color: #2C2C6C; /* Light background for each experience item */
    border: 1px solid #4DB5FF; /* Border for each item */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow effect */
    transition: transform 0.3s; /* Smooth scaling effect */
}

.experience__item:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.experience__item h4 {
    color: #fff; /* Darker color for the company name */
}
.exp-year {
    color: #fff;
    font-size: 11px !important;
}

.experience__item p {
    margin-bottom: 5px ; /* Space between paragraphs */
    color: #fff; /* Darker gray for text */
    font-size: 11px;

}

.btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff; /* Button color */
    color: white;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.3s;
}
.experience-year {
    font-size: 11px;
}

.btn:hover {
    background-color: #0056b3; /* Darker button color on hover */
}
.company-logos {
    display: flex;
    margin-left: -10px;
    font-size: 14px;
}
.dot-d {
    display: flex;
    margin-left: 0;
    text-wrap: wrap;
    font-size:13px;

}
/* For Tablets (768px to 1024px) */
@media (max-width: 1024px) {
    .about__container {
      flex-direction: column;
      align-items: center;
    }
  
    .about__me {
      width: 180px; /* Adjust width for tablets */
      height: 180px; /* Keep height equal to width for a square shape */
    }
  
    .about__me-img {
      object-fit: cover; /* Ensures image fills the container */
    }
  
    .about__tabs {
        flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
    }
  
    .about__content {
      margin-top: 2rem;
    }
  
    .btn {
      width: 100%;
      text-align: center;
      padding: 1rem 0;
    }
  }
  
  /* For Mobile Devices (480px to 768px) */
  @media (max-width: 768px) {
    .about__container {
      flex-direction: column;
      align-items: center;
    }
  
    .about__me {
      width: 120px; /* Adjust width for mobile */
      height: 120px; /* Keep height equal to width for a square shape */
    }
  
    .about__me-img {
      object-fit: cover; /* Ensures image fills the container */
    }
  
    .about__tabs {
        flex-wrap: wrap !important;
      align-items: center;
      gap: 0.5rem;
    }
  
    .about__content {
      margin-top: 2rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .btn {
      width: 100%;
      text-align: center;
      padding: 1rem 0;
    }
  }
  
  /* For Small Screens (mobile-first approach) */
  @media (max-width: 480px) {
     .about__container {
    flex-direction: column;
    align-items: center;
  }
    h2 {
      font-size: 1.2rem;
    }
  
    .about__content p {
        margin: 10px !important;
    }

    .experience__item h4 {
        text-align: center;  /* Horizontally center the text */
        margin: 0;  /* Remove any margin if needed */
        padding: 0;  /* Remove padding if it's interfering */
        font-size: 14px;  /* Optional: Adjust font size */
        display: flex; /* Use flexbox to center content */
        justify-content: center; /* Ensure it is centered horizontally */
        align-items: center; /* Center text vertically if the line height allows */
    }
    
    .experience__item {
        display: flex;  /* Ensure items inside are in flex container */
        flex-direction: column; /* Stack items vertically */
        align-items: center;  /* Center content inside experience__item */
        justify-content: center; /* Center content inside experience__item */
        text-align: center; /* Ensure text inside is centered */
    }

    .about__container {
        flex-direction: column;
        align-items: center;
    }

    .experience__item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(96% - 20px); /* Adjust size for mobile */
        margin: 10px;
    }
    .about__me {
      width: 100px; /* Adjust width for small screens */
      height: 200px; /* Keep height equal to width for a square shape */
    }
  
    .about__me-img {
      object-fit: cover; /* Ensures image fills the container */
    }
    .about__tabs {
        flex-direction: wrap;
    }
  
    .about__tabs button {
      padding: 0.8rem 10px;
      font-size: 0.9rem;
      width: 88px;
      margin: 1px 4px;
      gap: 0.1rem;
      
      
    }
  
    .about__content {
      padding: 1rem;
    }
    .experience__item {
        flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(96% - 20px);
    margin: 10px;
    }
    .about-me-p {
      font-size: 12px;
    }
  }