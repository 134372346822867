header {
    height: 14 0vh;
    padding: 7rem 7rem 2rem 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ======== cta =========*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
.btn {
    background-color: #4DB5FF;
}

/* =========== header socials =========*/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* =============== me ==========*/
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 24rem !important;
    position: relative;
    left: calc(50% - 11rem);
    margin-top: 6rem;
    bottom:0px;
    border-radius: 12rem 12em 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* ========== scroll ===========*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ============ media query (medium devices) =========== */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* ============ media query (small devices) =========== */

@media screen and (max-width: 600px) {

    header {
        height: 90vh;
        padding: 7rem 7rem 0 7rem;
        overflow: hidden;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
    .header__container h1 {
        font-size: 16px;
    }
    .btn {
        font-size: 11px;
    }
}